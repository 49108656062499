export const environment = {
    production: false,
    firebase: {
      apiKey: "AIzaSyDOuaeFwfxLyQtanaY5sCYB-wLvI--J-AY",
      authDomain: "carionic-e1f2d.firebaseapp.com",
      databaseURL: "https://carionic-e1f2d.firebaseio.com",
      storageBucket: "carionic-e1f2d.appspot.com",
      messagingSenderId: "585117608984"
    },
    dialogflow: {
      angularBot: '1cc828d9ae7941b6812b523fda87c3ac'

    }
}
;
