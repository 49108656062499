import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../app/services/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  { path: 'landing', loadChildren: './pages/landing/landing.module#LandingPageModule' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'menu', loadChildren: './pages/menu/menu.module#MenuPageModule' },
  { path: 'myorders', loadChildren: './pages/myorders/myorders.module#MyordersPageModule', canActivate: [AuthGuardService]},
  { path: 'wishlist', loadChildren: './pages/wishlist/wishlist.module#WishlistPageModule', canActivate: [AuthGuardService]},
  { path: 'home', loadChildren: './pages/home/home.module#HomePageModule', canActivate: [AuthGuardService]},
  { path: 'checkout', loadChildren: './pages/checkout/checkout.module#CheckoutPageModule',  canActivate: [AuthGuardService]},
  { path: 'cart', loadChildren: './pages/cart/cart.module#CartPageModule', canActivate: [AuthGuardService]},
  { path: 'test', loadChildren: './pages/test/test.module#TestPageModule' },
  { path: 'productdetail', loadChildren: './pages/productdetail/productdetail.module#ProductdetailPageModule' },
  { path: 'productdetail/:id', loadChildren: './pages/productdetail/productdetail.module#ProductdetailPageModule' },
  { path: 'myorderdetails/:id', loadChildren: './pages/myorderdetails/myorderdetails.module#MyorderdetailsPageModule' },
  { path: 'account', loadChildren: './pages/account/account.module#AccountPageModule' },
  { path: 'download', loadChildren: './pages/download/download.module#DownloadPageModule' },
  { path: 'myproducts', loadChildren: './pages/myproducts/myproducts.module#MyproductsPageModule' },
  { path: 'profile', loadChildren: './pages/profile/profile.module#ProfilePageModule' },
  { path: 'cartsetup', loadChildren: './pages/cartsetup/cartsetup.module#CartsetupPageModule' },
  { path: 'financials', loadChildren: './pages/financials/financials.module#FinancialsPageModule' },
  { path: 'settings', loadChildren: './pages/settings/settings.module#SettingsPageModule' }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {  preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
