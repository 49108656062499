import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from 'angularfire2';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { environment } from '../app/environments/environments';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { CartService } from '../app/services/cart.service';
import { AuthService } from '../app/services/auth.service';
import { AuthGuardService} from '../app/services/auth-guard.service';
import { IonicStorageModule } from '@ionic/storage';
import { DataService } from '../app/services/data.service';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { HttpClientModule } from '@angular/common/http';
import { SettingsPipe } from './pages/settings.pipe';
@NgModule({
  declarations: [AppComponent, SettingsPipe],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    HttpClientModule,
    AngularFireDatabaseModule,  
    AngularFireFunctionsModule,             
    AngularFireAuthModule, 
    IonicStorageModule.forRoot()],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    CartService, 
    AuthService,
    
    DataService,
    AuthGuardService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
