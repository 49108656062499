import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private router: Router, 
              public authService:AuthService) {

  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
     if(this.authService.getCurrentUser() == null ){
          this.router.navigate(['login']);
          return false;
      }
      return true;

  }

}